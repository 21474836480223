
import { Component, Vue, Watch } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import FilterInstant from '@/components/FilterInstant.vue';

import TextMoney from '@/components/table-items/TextMoney.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';

import AddBlock from './Add.vue';
import EditBlock from './Edit.vue';

import { GuiLoader } from '@library/gigant_ui';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import AppTable from '@/components/ui/table/Table.vue';
import IconFont from '@/components/icons/IconFont.vue';
import BonusModule from '@/store/settings/bonus';
import SettingsModule from '@/store/settings';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
  components: {
    TextNumber,
    TextMoney,
    TabsNav,
    FilterInstant,
    AppRow,
    AppCol,
    AppHeaderBtnAdd,
    AppTable,
    IconFont,
    AddBlock,
    EditBlock,
    GuiLoader,
  },
})
export default class SettingsBonusMain extends Vue {
  activeRow: Element | null = null;

  get tabsNav(): TabsNavInterface {
    return SettingsModule.tabsNav;
  }

  get currentTab(): string {
    return SettingsModule.allTabs.bonus.id;
  }

  get settings(): PageInterface {
    return BonusModule.pageSettings;
  }

  get isAdd(): boolean {
    return BonusModule.isAdd;
  }

  get isEdit(): boolean {
    return BonusModule.isEdit;
  }

  @Watch('activeRow')
  changeColorRow(el: HTMLElement, oldEl: HTMLElement): void {
    if (el) {
      el.classList.add('app-table-body-row--selected');
    }

    if (oldEl) {
      oldEl.classList.remove('app-table-body-row--selected');
    }
  }

  cancelEdit(): void {
    this.activeRow = null;
  }

  toAdd(): void {
    BonusModule.updateIsAdd(true);
  }

  toEdit(id: string, index: number): void {
    const table = this.$refs.table as { $el: HTMLElement };
    if (table) {
      this.activeRow = table.$el.querySelector(`.app-table-body-row--index-${index}`) as Element;
    }
    BonusModule.updateIsEdit({ isEdit: true, id: id.toString() });
  }

  returnBefore(): void {
    this.cancelEdit();
    BonusModule.updateIsAdd(false);
    BonusModule.updateIsEdit({ isEdit: false, id: '' });
  }

  selectAmount(value: string): void {
    BonusModule.updatePageAmountItems(value);
  }

  mounted(): void {
    SettingsModule.initTabsItems();

    BonusModule.init();
  }
}
