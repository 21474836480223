import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import BonusEntityModule from './entity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { getBonusList } from '@/api/bonus';
import { getClients } from '@/api/client';
import { getServiceTypeAll } from '@/api/templates';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { MultiSelectItem } from '@/interfaces/multiSelect.interface';

export const MODULE_NAME = 'bonuses';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class BonusModule extends PageBaseModule {
  isAdd = false;
  isEdit = false;
  clientItems: SelectItem[] = [];
  serviceTypeItems: MultiSelectItem[] = [];

  constructor(module: BonusModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'bonus/updatePage';
    page.setTitle('Бонусы');
    page.setTitleAdd('Добавить бонус');
    this.pageSettings = page.values;
  }

  @Mutation
  SET_IS_ADD(isAdd: boolean) {
    this.isAdd = isAdd;
  }

  @Mutation
  SET_IS_EDIT(isEdit: boolean) {
    this.isEdit = isEdit;
  }

  @Mutation
  SET_CLIENT_ITEMS(items: SelectItem[]) {
    this.clientItems = items;
  }

  @Mutation
  SET_SERVICE_TYPE_ITEMS(items: MultiSelectItem[]) {
    this.serviceTypeItems = items;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const tableInfo = uiTable.getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getBonusList(this.pageSettings.pageCurrent, itemsQuery);
      await this.context.dispatch('setList', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  updateIsAdd(isAdd: boolean) {
    BonusEntityModule.resetModel();
    this.SET_IS_EDIT(false);
    BonusEntityModule.SET_EDIT_ID('');
    this.SET_IS_ADD(isAdd);
  }

  @Action({ rawError: true })
  updateIsEdit(params: { isEdit: boolean; id: string }) {
    BonusEntityModule.resetModel();
    BonusEntityModule.SET_EDIT_ID(params.id);
    this.SET_IS_ADD(false);
    this.SET_IS_EDIT(params.isEdit);

    if (params.id && params.id.length > 0) {
      BonusEntityModule.getById();
    }
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
    await this.fetchClients();
    await this.fetchServiceTypes();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async fetchClients() {
    const result = await getClients(false);
    const clientItems = result.clients.map((item) => ({
      id: item.id.toString(),
      value: item.name,
    }));

    this.context.commit('SET_CLIENT_ITEMS', clientItems);
  }

  @Action({ rawError: true })
  async fetchServiceTypes() {
    const serviceTypes = await getServiceTypeAll();
    const serviceTypeItems = serviceTypes.map((item) => ({
      id: item.id,
      value: item.name,
      name: item.name,
    }));

    this.context.commit('SET_SERVICE_TYPE_ITEMS', serviceTypeItems);
  }
}

export default getModule(BonusModule);
