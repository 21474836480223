
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import BonusEntityModule from '@/store/settings/bonus/entity.ts';
import BonusModule from '@/store/settings/bonus';

@Component({
  components: {
    FormBlock,
    Loading,
  },
})
export default class BonusEntityEdit extends Form {
  get isLoading(): boolean {
    return BonusEntityModule.isLoading;
  }

  returnBefore(): void {
    this.$emit('cancel');
    BonusModule.updateIsEdit({ isEdit: false, id: '' });
  }
}
