/**
 * Global API for bonus.
 *
 */

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';
import { BonusActiveListItem, BonusEntity, BonusResponse } from '@/interfaces/bonusResponse.interface';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData, FormResponse } from '@/interfaces/shared';

/**
 * Get bonus list
 *
 * @param pageCurrent
 * @param pageAmountItems
 */
export const getBonusList = async (pageCurrent?: number, pageAmountItems?: string): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.bonus.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).getUrl();
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(url);

  return result.data.result.table;
};

/**
 * Get all active bonus list
 *
 * @param shiftEmployeeId
 */
export const getAllActiveBonusList = async (shiftEmployeeId: string): Promise<BonusActiveListItem[]> => {
  const url = API.bonus.getActiveList.replace('$1', shiftEmployeeId);
  const result = await makeGetRequest(url);

  return result.data.result.bonuses;
};

/**
 * Get bonus by id
 *
 * @param id
 */
export const getBonusById = async (id: string): Promise<BonusEntity> => {
  const url = API.bonus.getEntity.replace('$1', id);
  const result = await makeGetRequest<BonusResponse>(url);

  return result.data.result.bonus;
};

/**
 * Add new bonus
 *
 * @param data
 *
 * @access CAN_EDIT_BONUS
 */
export const addNewBonus = async (data: FormData): Promise<FormResponse> => {
  const url = API.bonus.createEntity;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save bonus
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_BONUS
 */
export const saveBonus = async (id: string, data: FormData): Promise<FormResponse> => {
  const url = API.bonus.editEntity.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete bonus
 *
 * @param id
 *
 * @access CAN_EDIT_BONUS
 */
export const deleteBonus = async (id: string): Promise<FormResponse> => {
  const url = API.bonus.deleteEntity.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};
