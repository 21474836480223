
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';

import BonusModule from '@/store/settings/bonus';

@Component({
  components: {
    FormBlock,
  },
})
export default class BonusEntityAdd extends Form {
  returnBefore(): void {
    BonusModule.updateIsAdd(false);
  }
}
