import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71e42bda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }
const _hoisted_3 = { class: "cp-flex cp-flex--align-center cp-flex--justify-between" }
const _hoisted_4 = { class: "app-table-bold-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_block = _resolveComponent("add-block")!
  const _component_edit_block = _resolveComponent("edit-block")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        ref: "header"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createVNode(_component_app_header_btn_add, { onClick: _ctx.toAdd }, null, 8, ["onClick"]), [
                [_directive_tooltip, 'Добавить бонус']
              ])
            ])
          ])
        ]),
        _: 1
      }, 512),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { absoluteCenter: "" })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_app_col, {
            key: 1,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_row, { class: "block-radius" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_table, {
                        ref: "table",
                        class: "app-table--row-pointer bonus-table",
                        headers: _ctx.settings.table.titles,
                        items: _ctx.settings.table.rows,
                        page: _ctx.settings.table.pagination.currentPage,
                        "total-pages": _ctx.settings.table.pagination.totalPages,
                        "total-items": _ctx.settings.table.totalItems,
                        "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
                        "is-hover": true,
                        onClickRow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toEdit($event.item.id, $event.indexItem))),
                        onChangePage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
                        onSelectAmount: _ctx.selectAmount
                      }, {
                        "item-name": _withCtx(({ value }) => [
                          _createElementVNode("span", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(value), 1),
                            _createVNode(_component_icon_font, {
                              class: "app-table-icon app-table-icon--row-hover",
                              icon: "pencil"
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_col, null, {
                    default: _withCtx(() => [
                      (_ctx.isAdd)
                        ? (_openBlock(), _createBlock(_component_add_block, { key: 0 }))
                        : (_ctx.isEdit)
                          ? (_openBlock(), _createBlock(_component_edit_block, {
                              key: 1,
                              onCancel: _ctx.cancelEdit
                            }, null, 8, ["onCancel"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}