
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import ModalBlock from '@/components/Modal.vue';

import BonusEntityModule from '@/store/settings/bonus/entity';
import { strings } from '@/lib/stringConst';
import BonusModule from '@/store/settings/bonus';
import { BonusModel, BonusTextFields } from '@/interfaces/bonusResponse.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  emits: ['returnBefore'],
  components: {
    FormInput,
    FormTextarea,
    FormButton,
    FormSelect,
    FormMultiSelect,
    ModalBlock,
    GuiFormCheckbox,
  },
})
export default class BonusEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  name = this.model.name ?? '';
  rocketWorkDescription = this.model.rocketWorkDescription ?? '';
  isActive = this.model.isActive ?? false;
  amount = this.model.amount ?? '';
  workedShifts = this.model.workedShifts ?? '';
  workedDays = this.model.workedDays ?? '';
  client: SelectItem = this.model.client ?? { id: '', value: '' };
  serviceTypes = this.model.serviceTypes;
  serviceTypesModify = {};

  deleteBonusModalShown = false;

  get text(): Record<string, string> {
    return strings;
  }

  get model(): BonusModel {
    return BonusEntityModule.model;
  }

  get isAdd(): boolean {
    return BonusModule.isAdd;
  }

  get isEdit(): boolean {
    return BonusModule.isEdit;
  }

  get clientItems() {
    return BonusModule.clientItems;
  }

  get serviceTypeItems() {
    return BonusModule.serviceTypeItems;
  }

  isActiveHandler(selected: { bool: boolean }): void {
    BonusEntityModule.updateIsActive(selected.bool);
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  prepareFields(): BonusTextFields {
    const data: BonusTextFields = {
      name: this.name,
      rocketWorkDescription: this.rocketWorkDescription,
      isActive: this.isActive ? '1' : '0',
      amount: this.amount,
      ...this.serviceTypesModify,
    };

    if (this.workedShifts) {
      data.workedShifts = this.workedShifts;
    }

    if (this.workedDays) {
      data.workedDays = this.workedDays;
    }

    if (this.client.id) {
      data.client = this.client.id;
    }

    return data;
  }

  async add(): Promise<void> {
    try {
      await BonusEntityModule.addNew(this.prepareFields());

      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async edit(): Promise<void> {
    try {
      await BonusEntityModule.saveUpdate(this.prepareFields());

      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async deleteBonus(): Promise<void> {
    this.deleteBonusModalShown = false;

    try {
      await BonusEntityModule.delete();

      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }
}
