import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { BonusEntity, BonusModel, BonusTextFields } from '@/interfaces/bonusResponse.interface';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import BonusModule from './index';
import { addNewBonus, getBonusById, saveBonus, deleteBonus } from '@/api/bonus';
import { FormData, FormResponse } from '@/interfaces/shared';

import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'bonus';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class BonusEntityModule extends EntityBaseModule {
  model: BonusModel = {
    id: '',
    name: '',
    rocketWorkDescription: '',
    amount: '',
    isActive: false,
    workedShifts: '',
    workedDays: '',
    client: { id: '', value: '' },
    serviceTypes: [],
  };
  editId: string | null = null;

  constructor(module: BonusEntityModule) {
    super(module);
  }

  @Mutation
  SET_EDIT_ID(id: string) {
    this.editId = id;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Action({ rawError: true })
  async getById() {
    try {
      const result = await getBonusById(this.editId as string);

      this.setInfo(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfo(info: BonusEntity) {
    BonusModule.updateTitleEdit(info.name);
    this.updateIsLoading(false);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkDescription', value: info.rocketWorkDescription });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'amount', value: info.amount });
    this.context.commit('SET_MODEL_VALUE', { key: 'workedShifts', value: info.workedShifts });
    this.context.commit('SET_MODEL_VALUE', { key: 'workedDays', value: info.workedDays });
    if (info.client) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'client',
        //@ts-ignore
        value: { id: info.client.id, value: info.client.name },
      });
    }
    if (info.serviceTypes) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'serviceTypes',
        value: info.serviceTypes.map((item) => ({
          id: item.id,
          value: item.name,
          name: item.name,
        })),
      });
    }
  }

  @Action({ rawError: true })
  async addNew(data: BonusTextFields) {
    try {
      const result = await addNewBonus(data as FormData);

      if (!(result as FormResponse).message) {
        ResponseHandlerModule.showNotify({ message: 'Бонус создан', type: 'ok' });
        await BonusModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(data: BonusTextFields) {
    try {
      const result = await saveBonus(this.editId as string, data as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
        await BonusModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async delete() {
    try {
      const result = await deleteBonus(this.editId as string);

      if (!(result as { message: string }).message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_DELETE, type: 'ok' });
        await BonusModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: (result as { message: string }).message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  resetModel() {
    BonusModule.updateTitleEdit('');
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkDescription', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'amount', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'workedShifts', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'workedDays', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'client', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'serviceTypes', value: [] });

    this.updateIsLoading(true);
  }
}

export default getModule(BonusEntityModule);
